* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

span {
  display: inline-block;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  text-transform: inherit;
}

a {
  text-decoration: none;
  display: inline-block;
}

a:hover {
  text-decoration: underline;
}

li {
  list-style: none;
  list-style-position: inside;
}

img,
svg {
  display: block;
  max-width: 100%;
}

.container-wrap {
  padding: 1rem;
}

@media (min-width: 1232px) {
  .container-wrap {
    width: 1216px;
    margin: 0 auto;
    padding: 0;
  }
}
.form-template br {
  display: none;
}

.form-template span.wpcf7-form-control-wrap {
  display: block;
  margin-bottom: 30px;
}

.form-template span.wpcf7-form-control-wrap:last-child {
  margin-bottom: 0;
}

.form-template input[type=text],
.form-template input[type=email],
.form-template input[type=tel],
.form-template textarea {
  background: #EFF0F7;
  border-radius: 16px;
  border: none;
  color: #14142B;
  border: 2px solid transparent;
  font-size: 13px;
  display: block;
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  width: 100%;
  font-weight: 400;
  box-sizing: border-box;
}

.form-template input[type=text] :-ms-input-placeholder,
.form-template input[type=email] :-ms-input-placeholder,
.form-template input[type=tel] :-ms-input-placeholder,
.form-template textarea :-ms-input-placeholder {
  color: #6E7191;
  font-size: 15px;
  font-weight: 400;
}

.form-template input[type=text] ::placeholder,
.form-template input[type=email] ::placeholder,
.form-template input[type=tel] ::placeholder,
.form-template textarea ::placeholder {
  color: #6E7191;
  font-size: 15px;
  font-weight: 400;
}

.form-template input[type=text] :focus,
.form-template input[type=email] :focus,
.form-template input[type=tel] :focus,
.form-template textarea :focus {
  border-color: #14142B;
}

.form-template input[type=text].wpcf7-not-valid,
.form-template input[type=email].wpcf7-not-valid,
.form-template input[type=tel].wpcf7-not-valid,
.form-template textarea.wpcf7-not-valid {
  background-color: #FFECFC;
  border-color: #CA024F;
}

.form-template .two-inputs {
  margin-bottom: 30px;
}

.form-template .wpcf7-not-valid-tip {
  display: none;
}

.form-template .button-step-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  gap: 2rem;
  margin-top: 40px;
}

.form-template .button-step-form .steps-itens {
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
  position: relative;
  z-index: 1;
}

.form-template .button-step-form .steps-itens .step--item {
  background-color: #D9D9D9;
  color: #4E4B66;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.75px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  transition: background-color 0.5s ease, color 0.5s ease;
  position: relative;
}

.form-template .button-step-form .steps-itens .step--item:after {
  content: "";
  position: absolute;
  top: 50%;
  right: -33px;
  transform: translateY(-50%);
  background-color: #D9D9D9;
  height: 6px;
  width: 2.5rem;
  z-index: -1;
}

.form-template .button-step-form .steps-itens .step--item:last-child:after {
  display: none;
}

.form-template .button-step-form .steps-itens .step--item.form-complete {
  background-color: #27AF00;
  color: #FCFCFC;
}

.form-template .button-form {
  background-color: #BCFAA1;
  border-color: #BCFAA1;
  color: #27AF00;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.25px;
  padding: 12px 32px;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.form-template .button-form:after {
  content: "";
  background-image: url("../../assets/images/svg/icon-arrow-right-green.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 13px;
  height: 13px;
  position: relative;
  margin-left: 3px;
  display: inline-block;
  transition: transform 0.5s ease;
}

.form-template .button-form:hover:after {
  transform: translateX(0.5rem);
}

.form-template .button-form-steps {
  background-color: #BCFAA1;
  border-color: #BCFAA1;
  color: #27AF00;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.25px;
  padding: 9px 23.5px;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.form-template .button-form-steps:after {
  content: "";
  background-image: url("../../assets/images/svg/icon-arrow-right-green.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 13px;
  height: 13px;
  position: relative;
  margin-left: 3px;
  display: inline-block;
  transition: transform 0.5s ease;
}

.form-template .button-form-steps:hover:after {
  transform: translateX(0.5rem);
}

.form-template .radio-container {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.5rem;
  padding-left: 0.3rem;
}

.step-container {
  display: none;
}

.step-container.active {
  display: block;
}

.wpcf7-response-output {
  border-radius: 16px;
  padding: 1rem !important;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #373737;
  margin: 1.5rem 0 0 !important;
}

@media (min-width: 1024px) {
  .form-template .two-inputs {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    gap: 1rem;
  }
  .form-template .two-inputs span {
    margin: 0;
  }
}
.placeholder {
  position: relative;
}

.placeholder .placeholder--text {
  color: #6E7191;
  font-size: 15px;
  font-weight: 400;
  position: absolute;
  top: 28px;
  transform: translateY(-50%);
  left: 26px;
  display: inline-block;
  transition: top 0.5s ease, font-szie 0.4s ease;
  pointer-events: none;
}

.placeholder.focus .placeholder--text {
  font-size: 11px;
  top: 8px;
  transform: translateY(0);
  transition: top 0.5s ease, font-szie 0.4s ease;
}

.placeholder input,
.placeholder textarea {
  padding: 1.5rem 1.5rem 0.8rem !important;
}

.form-contact .placeholder.focus .placeholder--text {
  top: 1px;
}

.button {
  padding: 20px 32px;
  border: 2px solid;
  box-sizing: border-box;
  border-radius: 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.button:hover {
  text-decoration: none;
}

.button--white {
  background-color: #FCFCFC;
  border-color: #fcfcfc;
  color: #14142B;
}

.button--white:hover {
  background-color: transparent;
  color: #FCFCFC;
}

.button-border--white {
  background-color: transparent;
  border-color: #fcfcfc;
  color: #fcfcfc;
}

.button-border--white:hover {
  background-color: #fcfcfc;
  color: #14142B;
}

.button-green {
  color: #27AF00;
  background: #BCFAA1;
  border-radius: 8px;
  border-color: #BCFAA1;
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls],
.tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  transition: all 0s;
}

.tns-slider > .tns-item {
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: baseline;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal,
.tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div,
.tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.3333333%;
  width: -moz-calc(100% * 70 / 3);
  width: 2333.3333333333%;
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.4285714%;
  width: -moz-calc(100% / 70);
  width: 1.4285714286%;
  height: 10px;
  float: left;
}

.grid {
  display: -ms-grid;
  display: grid;
}

@media (min-width: 992px) {
  .two_grids {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .three_grids {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
  }
  .four_grids {
    -ms-grid-columns: (1fr)[4];
        grid-template-columns: repeat(4, 1fr);
  }
  .six_grids {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1232px) {
  .four-grids {
    -ms-grid-columns: (1fr)[4];
        grid-template-columns: repeat(4, 1fr);
  }
  .five_grids {
    -ms-grid-columns: (1fr)[5];
        grid-template-columns: repeat(5, 1fr);
  }
  .six-grids {
    -ms-grid-columns: (1fr)[6];
        grid-template-columns: repeat(6, 1fr);
  }
}
.header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: #FCFCFC;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.header-middle {
  width: 100%;
  height: 92px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.header-middle .container-wrap {
  width: 100%;
}

.header-middle_container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.header-middle_container .logo-container {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 996px) {
  .header-middle_container .logo-container {
    position: relative;
    left: auto;
    transform: translateX(0);
  }
}
@media (min-width: 1232px) {
  .header-middle .container-wrap {
    width: 1216px;
  }
}
.menu .menu-item {
  position: relative;
}

.menu .menu-item a {
  font-size: 15px;
  color: #27AF00;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.75px;
}

.menu .menu-item a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #27AF00;
  transition: width 0.1s ease;
  border-radius: 26px;
}

.menu .menu-item.current-menu-item a:after {
  width: 100%;
}

.menu .menu-item:hover a {
  text-decoration: none;
}

.menu .menu-item:hover a:after {
  transition: width 0.5s ease;
  width: 100%;
}

.menu-desk {
  display: none;
}

@media (min-width: 992px) {
  .menu-desk {
    display: block;
  }
}
.menu-desk .menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  gap: 2rem;
}

.hamburguer-menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  gap: 4px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 21px;
  cursor: pointer;
}

.hamburguer-menu .bar {
  width: 100%;
  height: 3px;
  background-color: #27AF00;
}

.menu-mobile {
  display: none;
  padding: 0.5rem 0;
  position: absolute;
  background: #FCFCFC;
  top: 92px;
  z-index: 9;
}

.menu-mobile.active {
  display: block;
  width: 100%;
}

.menu-mobile .menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  gap: 0.5rem;
}

@media (min-width: 992px) {
  .hamburguer-menu {
    display: none;
  }
  .menu-mobile {
    display: none;
  }
}
.footer-menu .menu-item a {
  color: #4E4B66;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.75px;
}

.footer {
  margin-top: 4rem;
}

.footer-top_container {
  gap: 2rem;
}

.footer--item:first-child {
  -ms-flex-order: 5;
      order: 5;
}

.footer--item .logo_container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  max-width: 248px;
}

.footer--item .redes-sociais_container {
  margin-top: 3rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  gap: 2rem;
  max-width: 248px;
}

.footer--item .title-container {
  margin-bottom: 13px;
}

.footer--item .title-container h5 {
  color: #14142B;
  letter-spacing: 0.75px;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
}

.footer-bottom {
  text-align: center;
  padding: 2rem 0 1.625rem;
}

.footer-bottom .copy-right {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #A0A3BD;
}

@media (min-width: 992px) {
  .footer-top_container {
    gap: 4rem;
    -ms-grid-columns: 1fr (156px)[3];
        grid-template-columns: 1fr repeat(3, 156px);
  }
  .footer--item:first-child {
    -ms-flex-order: 0;
        order: 0;
  }
  .footer--item .redes-sociais_container a {
    transition: transform 0.5s ease;
  }
  .footer--item .redes-sociais_container a:hover {
    transform: scale(0.9);
  }
  .footer-bottom {
    padding: 4rem 0 1.625rem;
  }
}
.widget-social {
  position: fixed;
  right: 1rem;
  bottom: 2rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  gap: 0.5rem;
}

.widget-social--item {
  width: 51px;
  height: 51px;
  background-color: #27AF00;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  box-shadow: 0px 0px 72px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  transition: transform 0.3s;
}

.widget-social--item:hover {
  transform: scale(0.9);
}

@media (min-width: 1024px) {
  .widget-social {
    right: 2rem;
  }
}
.button-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}